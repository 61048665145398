<template>
  <div class="pagination flex justify-between" @click.prevent.capture="onClick">
    <ButtonStyle v-slot="{ classes }" :small="isSmall">
      <component
        :is="page > 0 ? NuxtLink : 'button'"
        :href="page > 0 ? doGetPageLink(page - 1) : undefined"
        :class="classes"
        :disabled="page === 0"
      >
        <SpriteSymbol
          name="arrow"
          class="transform rotate-180 h-10 w-20 mr-10"
        />
        <span>{{ $texts('back', 'Zurück') }}</span>
      </component>
    </ButtonStyle>
    <div class="buttons mobile-only:hidden items-center">
      <PaginationPage
        v-for="p in beginningPages"
        :key="'begin_' + p"
        :page="p"
        :to="doGetPageLink(p - 1)"
        :active="p - 1 === page"
        :small="isSmall"
      />
      <div
        v-if="hasBeginningGap"
        class="button is-circle bg-transparent pointer-events-none"
        :class="{ 'is-narrow': isSmall }"
        aria-hidden="true"
      >
        &hellip;
      </div>
      <PaginationPage
        v-for="p in slidingWindowPages"
        :key="'sliding_' + p"
        :page="p"
        :to="doGetPageLink(p - 1)"
        :active="p - 1 === page"
        :small="isSmall"
      />

      <div
        v-if="hasEndingGap"
        aria-hidden="true"
        class="button is-circle bg-transparent pointer-events-none"
        :class="{ 'is-narrow': isSmall }"
      >
        &hellip;
      </div>
      <PaginationPage
        v-for="p in endingPages"
        :key="'ending_' + p"
        :page="p"
        :to="doGetPageLink(p - 1)"
        :active="p - 1 === page"
        :small="isSmall"
      />
    </div>
    <ButtonStyle v-slot="{ classes }" :small="isSmall">
      <component
        :is="page < totalPages - 1 ? NuxtLink : 'button'"
        :href="page < totalPages - 1 ? doGetPageLink(page + 1) : undefined"
        :class="classes"
        :disabled="page > totalPages - 2"
      >
        <span>{{ $texts('continue', 'Weiter') }}</span>
        <SpriteSymbol name="arrow" class="h-10 w-20 ml-10" />
      </component>
    </ButtonStyle>
  </div>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'
import { NuxtLink } from '#components'

const route = useRoute()
const router = useRouter()

function range(start: number, end: number) {
  const r: number[] = []

  if (typeof start !== 'number' || typeof end !== 'number') {
    return r
  }

  if (start > end) {
    const temp = start
    start = end
    end = temp
  }

  for (let i = start; i <= end; i++) {
    r.push(i)
  }

  return r
}

type GetPageLink = (page: number) => RouteLocationRaw

const defaultGetPageLink: GetPageLink = function (page: number) {
  const query = {
    ...route.query,
  }
  if (page <= 0) {
    delete query.page
    return {
      name: route.name!,
      params: route.params,
      query,
    }
  }

  return {
    name: route.name!,
    params: route.params,
    query: {
      ...query,
      page,
    },
  }
}

const props = withDefaults(
  defineProps<{
    page?: number
    totalPages?: number
    nonSlidingSize?: number
    getPageLink?: GetPageLink
    isSmall?: boolean
  }>(),
  {
    page: 0,
    totalPages: 0,
    nonSlidingSize: 9,
    getPageLink: undefined,
    isSmall: false,
  },
)

const emit = defineEmits<{
  (e: 'changePage'): void
}>()

const isSliding = computed(() => {
  return props.totalPages > props.nonSlidingSize
})

const slidingEndingSize = 1
const slidingWindowSize = 3

const beginningPages = computed(() => {
  return range(1, isSliding.value ? slidingEndingSize : props.totalPages)
})

const lastBeginningPage = computed(() => {
  return beginningPages.value[beginningPages.value.length - 1]
})

const firstWindowPage = computed(() => {
  return slidingWindowPages.value[0]
})

const endingPages = computed(() => {
  if (!isSliding.value) {
    return []
  }

  return range(props.totalPages - slidingEndingSize + 1, props.totalPages)
})

const firstEndingPage = computed(() => {
  return endingPages.value[0]
})

const slidingWindowHalf = computed(() => {
  let half = slidingWindowSize / 2

  if (slidingWindowSize % 2 === 1) {
    half -= 0.5
  }

  return half
})

const slidingWindowPages = computed(() => {
  if (!isSliding.value) {
    return []
  }

  const startOffset = lastBeginningPage.value + slidingWindowHalf.value
  const endOffset = firstEndingPage.value - slidingWindowHalf.value

  if (props.page <= startOffset) {
    return range(
      lastBeginningPage.value + 1,
      lastBeginningPage.value + slidingWindowSize,
    )
  }

  if (props.page > startOffset && props.page < endOffset) {
    let upperHalfForEvenWindowSizes = slidingWindowHalf.value
    if (slidingWindowSize % 2 === 0) {
      upperHalfForEvenWindowSizes /= 2
    }

    return range(
      -slidingWindowHalf.value + props.page,
      upperHalfForEvenWindowSizes + props.page,
    )
  }

  return range(
    firstEndingPage.value - slidingWindowSize,
    firstEndingPage.value - 1,
  )
})

const hasBeginningGap = computed(() => {
  if (!isSliding.value) {
    return false
  }

  return lastBeginningPage.value + 1 !== firstWindowPage.value
})

const lastWindowPage = computed(() => {
  return slidingWindowPages.value[slidingWindowPages.value.length - 1]
})

const hasEndingGap = computed(() => {
  if (!isSliding.value) {
    return false
  }

  return lastWindowPage.value + 1 !== firstEndingPage.value
})

function doGetPageLink(page: number): string {
  if (props.getPageLink) {
    return router.resolve(props.getPageLink(page)).href
  }

  return router.resolve(defaultGetPageLink(page)).href
}

async function onClick(e: MouseEvent) {
  if (!(e.target instanceof HTMLElement || e.target instanceof SVGElement)) {
    return
  }
  const link = e.target.closest('a')
  if (!link) {
    return
  }
  const href = link.getAttribute('href')
  if (!href) {
    return
  }
  await router.push(href)
  emit('changePage')
}
</script>
