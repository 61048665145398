export default function (scrollToEl: Ref<HTMLElement | null>) {
  const onChangePage = () => {
    if (!scrollToEl.value) {
      return
    }

    const rect = scrollToEl.value.getBoundingClientRect()

    // Don't scroll to the element if it is already largly visible.
    if (rect.y > 50) {
      return
    }

    // Scroll to the top of the results list when the user changes the page
    // using click. We don't want to watch the page property, because that
    // would scroll everytime the page changes, even when the user uses goes
    // back/forwards in the browser history.
    scrollToEl.value.scrollIntoView({
      behavior: 'smooth',
    })

    // Focus the first result in the list, so that when pressing "Tab", the
    // user is not focusing the next pagination button (and thus scrolling
    // all the way back down).
    const firstResult = scrollToEl.value.querySelector('ol li a')
    if (firstResult instanceof HTMLElement) {
      firstResult.focus()
    }
  }

  return { onChangePage }
}
